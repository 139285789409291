<template>
  <div class="overlay" :class="{ active: isActive }" v-click-outside="close">
    <div class="trigger" @click="isActive = !isActive" :class="{ active: isActive }">
      <slot name="icon" v-if="!isActive"></slot>
      <img src="@/assets/img/icons/circular-menu--close.svg" v-else class="close" alt="❌" />
    </div>
    <div class="overlay__items" v-if="isActive" :class="{ 'btn--alt-grid': this.setUp.length > 4 }">
      <button
          class="btn"
          :class="[`btn-${i + 1}`, `btn--${item}`]"
          v-for="(item, i) in this.setUp"
          :key="item"
          @click="clickBtn(item)"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    setUp: Array
  },
  data: () => ({
    isActive: false
  }),
  methods: {
    clickBtn(action) {
      this.$emit('clickedFromCircleMenu', action)
      this.isActive = false
    },
    close() {
      if (this.isActive) {
        this.isActive = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.overlay
  height: rem(40)
  +media((width: (320: rem(32), 960: rem(40))))
  +media((border-radius: (320: 0, 960: 50%)))
  transition: cubic-bezier(0, 0, 0, 1.35) .3s
  overflow: hidden
  position: absolute
  +media((overflow: (320: unset, 960: hidden)))
  right: 0
  top: 50%
  transform: translateY(-50%)
  &__items
    height: 100%
    width: 100%
    +media((border-radius: (320: 0, 960: 50%)))
    overflow: hidden
    display: grid
    +media((grid-template-columns: (320: 1fr, 960: 1fr 1fr)))
    +media((grid-template-rows: (320: 1fr, 960: 1fr 1fr)))
  &.active
    +media((width: (320: rem(60), 960: rem(136))))
    +media((height: (320: auto, 960: rem(136))))
    +media((right: (320: 0, 960: rem(-48))))
    +media((background: (320: #fff, 960: rgba(0, 0, 0, 0.8))))
    +media((transform: (320: translate(0, rem(-34)) , 960: translate(0, -50%) rotate(45deg))))
    box-shadow: 0 rem(5) rem(20) rgba(51, 51, 51, 0.3), 0 rem(1) rem(4) rgba(51, 51, 51, 0.4)
    z-index: 1
  .trigger
    +media((border-radius: (320: 0, 960: 50%)))
    +media((height: (320: rem(68), 960: rem(40))))
    +media((width: (320: 100%, 960: rem(40))))
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    position: absolute
    +media((position: (320: relative, 960: absolute)))
    left: 50%
    top: 50%
    z-index: 1
    transform: translate(-50%, -50%)
    transition: .5s
    cursor: pointer
    box-sizing: border-box
    border: rem(1) solid transparent
    .icon
      background-image: url("~@/assets/img/menu.svg")
      background-repeat: no-repeat
      background-position: center
      height: 100%
      width: 100%
      position: relative
    &.active
      +media((background-color: (320: rgba(227, 7, 19, 0.75), 960: #323232)))
      +media((border: (320: none, 960: rem(1) solid #999999)))
      +media((top: (320: 0, 960: 50%)))
      +media((transform: (320: translate(-50%, 0), 960: translate(-50%, -50%))))
      &:hover
        +media((background-color: (320: rgba(227, 7, 19, 0.75), 960: #323232)))
      .close
        +media((transform: (320: rotate(0), 960: rotate(45deg))))
  .btn
    +media((width: (320: rem(60), 960: rem(68))))
    +media((height: (320: rem(56), 960: rem(68))))
    border: none
    box-shadow: none
    outline: none
    margin: 0
    padding: 0
    cursor: pointer
    background-color: transparent
    transition: .5s
    background-repeat: no-repeat
    background-position: center
    @media (any-hover: hover)
      &:hover
        background-color: #fff
    &-1, &-2, &-3, &-4
      +media((background-position: (320: center, 960: rem(6) rem(6))))
    &--up
      +media((background-image: (320: url("~@/assets/img/circleMenu/up--mobile.svg"), 960: url("~@/assets/img/circleMenu/up.svg"))))
      grid-area: 1 / 1
      +media((grid-area: (320: unset, 960: '1 / 1')))
      @media (any-hover: hover)
        &:hover
          background-image: url("~@/assets/img/circleMenu/up--active.svg")
    &--down
      +media((grid-area: (320: unset, 960: '2 / 2')))
      +media((background-image: (320: url("~@/assets/img/circleMenu/down--mobile.svg"), 960: url("~@/assets/img/circleMenu/down.svg"))))
      @media (any-hover: hover)
        &:hover
          background-image: url("~@/assets/img/circleMenu/down--active.svg")
    &--delete
      +media((grid-area: (320: unset, 960: '1 / 2')))
      +media((background-image: (320: url("~@/assets/img/circleMenu/trash--mobile.svg"), 960: url("~@/assets/img/circleMenu/trash.svg"))))
      @media (any-hover: hover)
        &:hover
          background-image: url("~@/assets/img/circleMenu/trash--active.svg")
    &--addFastener
      +media((grid-area: (320: unset, 960: '2 / 1')))
      +media((background-image: (320: url("~@/assets/img/circleMenu/addFastener--mobile.svg"), 960: url("~@/assets/img/circleMenu/addFastener.svg"))))
      @media (any-hover: hover)
        &:hover
          background-image: url("~@/assets/img/circleMenu/addFastener--active.svg")
    &--removeFastener
      +media((grid-area: (320: unset, 960: '2 / 1')))
      +media((background-image: (320: url("~@/assets/img/circleMenu/removeFastener--mobile.svg"), 960: url("~@/assets/img/circleMenu/removeFastener.svg"))))
      @media (any-hover: hover)
        &:hover
          background-image: url("~@/assets/img/circleMenu/removeFastener--active.svg")
    &--multilayer
      +media((background-image: (320: url("~@/assets/img/circleMenu/multilayer--add-mobile.svg"), 960: url("~@/assets/img/circleMenu/multilayer.svg"))))
      @media (any-hover: hover)
        &:hover
          +media((background-image: (320: url("~@/assets/img/circleMenu/multilayer--add-mobile-active.svg"), 960: url("~@/assets/img/circleMenu/multilayer--active.svg"))))
.elements_multilayer + .overlay
  top: rem(20)
  transform: none
  &.active
    +media((transform: (960: rotate(45deg))))
    .btn--multilayer
      +media((background-image: (320: url("~@/assets/img/circleMenu/multilayer--remove-mobile.svg"), 960: url("~@/assets/img/circleMenu/multilayer--remove.svg"))))
      @media (any-hover: hover)
        &:hover
          +media((background-image: (320: url("~@/assets/img/circleMenu/multilayer--remove-mobile-active.svg"), 960: url("~@/assets/img/circleMenu/multilayer--remove-active.svg"))))
.elements__left-multilayer .overlay
  transform: translate(100%, -50%)
  &.active
    z-index: 2
    +media((transform: (960: translate(25%, -50%) rotate(45deg))))
.overlay
  &__items.btn--alt-grid
    +media((grid-template-columns: (320: 1fr, 960: rem(50) rem(38) rem(50))))
    +media((grid-template-rows: (320: 1fr, 960: rem(50) rem(38) rem(50))))
    .btn
      width: 100%
      +media((height: (960: 100%)))
      &:hover
        background-color: transparent
      &--up
        +media((grid-area: (320: unset, 960: '1 / 2')))
        +media((background-position: (320: center, 960: -10px 0px)))
      &--delete
        +media((grid-area: (320: unset, 960: '1 / 3')))
        +media((background-position: (320: center, 960: -10px 0px)))
      &--addFastener
        +media((grid-area: (320: unset, 960: '2 / 1')))
        +media((background-position: (320: center, 960: 0px -10px)))
      &--down
        +media((grid-area: (320: unset, 960: '2 / 3')))
        +media((background-position: (320: center, 960: -4px -7px)))
      &--multilayer
        +media((grid-area: (320: unset, 960: '3 / 1')))
        +media((background-position: (320: center, 960: 8px -16px)))
      &--removeFastener
        +media((grid-area: (320: unset, 960: '3 / 2')))
        +media((background-position: (320: center, 960: -7px -4px)))
</style>
