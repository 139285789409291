<template>
  <div class="layers-products__filter">
    <div class="layers-products__filter-item">
      <input
        id="category-none"
        type="radio"
        class="input"
        value=""
        @change="returnFilteredProducts"
        v-model="pickedCategory"
      />
      <label for="category-none" class="label">
        {{ $t('message.productsModal.allMaterials') }}
      </label>
    </div>
    <div class="layers-products__filter-item" v-for="category in categories" :key="category.id">
      <input
        type="radio"
        class="input"
        @change="returnFilteredProducts"
        :value="category.id"
        v-model="pickedCategory"
        :id="`category-${category.id}`"
      />
      <label :for="`category-${category.id}`" class="label">
        {{ category.name }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: Array,
    products: Array
  },
  data: () => ({
    pickedCategory: ''
  }),
  methods: {
    returnFilteredProducts() {
      this.$emit('returnFilteredProducts', this.filteredProducts)
    }
  },
  computed: {
    filteredProducts() {
      return this.pickedCategory === ''
        ? this.products
        : this.products.filter(y => y.category === this.pickedCategory)
    }
  }
}
</script>

<style scoped lang="sass">
.layers-products
  &__filter
    display: flex
    align-items: center
    flex-wrap: wrap
    flex-direction: row
    +media((padding: (320: 0 rem(16), 960: 0)))
    +media((margin-bottom: (320: rem(16), 960: rem(32))))
    &-item
      .input
        display: none
        pointer-events: none
        position: absolute
        opacity: 0
        &:checked
          + label
            background: $red
            color: #fff
      .label
        background: #ECECEC
        border: rem(1) solid #fff
        @extend .fs-14
        +media((font-size: (320: rem(12), 960: rem(14))))
        color: $light-black
        cursor: pointer
        display: flex
        +media((padding: (320: rem(7) rem(16), 960: rem(10) rem(16))))
        transition: .5s
        &:hover
          background: $red-hover
          color: #fff
</style>
